import axios from "axios"; // 引入axios
// import QS from "qs"; // 引入qs模块，用来序列化post类型的数据

// const NODE_ENV='development'
// let baseURL='https://gyserver.smartyunzhou.com:9000';
// axios.defaults.baseURL = "https://gyserver.smartyunzhou.com:9000";
// if(NODE_ENV=='development'){
// 	baseURL='http://123.56.108.165:9000'
// }else{
// 	baseURL='http://123.56.108.165:9000'
// }
// if (process.env.NODE_ENV === 'development') {
//     axios.defaults.baseURL = 'http://123.56.108.165:9000/';
//     // axios.defaults.baseURL = 'http://http://localhost:9000/';
//     // axios.defaults.baseURL = 'http://119.3.177.49:8112/';
// }
// if (process.env.NODE_ENV === 'production') {
//     axios.defaults.baseURL = 'http://123.56.108.165:9000/';
//     // axios.defaults.baseURL = 'http://http://localhost:9000/';
//     // axios.defaults.baseURL = 'http://119.3.177.49:8112/';
// }
// if (process.env.NODE_ENV === 'debug') {
//     axios.defaults.baseURL = '';
// }

// 请求超时时间
// axios.defaults.timeout = 10000;

// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// let windowLoadingcontrol = false;

// 请求拦截器
// axios.interceptors.request.use(config => {
//     windowLoadingcontrol = setTimeout(() => { // 0.5秒内的接口不显示loading
//         if (windowLoadingcontrol) {
//             // Toast.loading({
//             //     message: '加载中...',
//             //     forbidClick: true,
//             //     duration: 0
//             // });
//         }
//         ;
//     }, 1000);

//     // Toast.loading({
//     //     message: '加载中...',
//     //     forbidClick: true,
//     //     duration: 0
//     // });

//     if (localStorage.getItem('access_token')) {
//         config.headers.Authorization = localStorage.getItem('access_token');
//     }

//     return config;
// }, error => {
//     Toast.clear();
//     return Promise.error(error);
// });

// // 响应拦截器
// axios.interceptors.response.use(response => {
//     clearTimeout(windowLoadingcontrol);
//     windowLoadingcontrol = false;

//     if (response.data.code == '3001') {
//         localStorage.removeItem("access_token");
//     }

//     return response;
// }, (error) => {
//     clearTimeout(windowLoadingcontrol);
//     windowLoadingcontrol = false;
//     Toast.clear();

//     return Promise.reject(error)
// })

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
function get(url, params) {
  url = "https://gaoyou.smartyunzhou.com" + url;
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
function post(url, params) {
  url = "https://gaoyou.smartyunzhou.com" + url;
  return new Promise((resolve, reject) => {
    // var parameters = QS.stringify(params);
    // var parameters =params
    axios
      .post(url, { params: params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

/**
 * put方法， 对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
// function put(url, params) {
//     return new Promise((resolve, reject) => {
//         axios.put(url, QS.stringify(params)).then(res => {
//             resolve(res.data);
//         }).catch(err => {
//             reject(err.data);
//         })
//     });
// }

/**
 * del方法， 对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
// function del(url, params) {
//     return new Promise((resolve, reject) => {
//         axios.delete(url, QS.stringify({ data: params })).then(res => {
//             resolve(res.data);
//         }).catch(err => {
//             reject(err.data);
//         })
//     });
// }

/**
 * upload方法   文件上传
 * @param {Array} files [文件对象数组]
 */
// function upload(files) {
//     let param = new FormData(); //创建form对象
//     files.map((item) => {
//         param.append('files', item); //通过append向form对象添加数据
//     })
//     let config = {
//         headers: { 'Content-Type': 'multipart/form-data' }
//     };
//     return new Promise((resolve, reject) => {
//         axios.post('/api/file-service/file/upload', param, config).then(res => {
//             resolve(res.data);
//         }).catch(err => {
//             reject(err.data);
//         })
//     });
// }

/**
 * download方法
 * @param {String} type [请求的方式]
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} fileType [导出文件类型] 默认值 xls
 * @param {String} fileName [导出文件名称] 默认值 导出文件
 */
// function download(url, params, fileType, fileName) {
//     fileType = fileType || 'xls';
//     fileName = fileName || '导出文件';
//     let config = {
//         params: params,
//         headers: {
//             'content-disposition': "attachment;filename=total." + fileType,
//             'Content-Type': 'application/x-download;charset=utf-8',
//         },
//         responseType: 'blob'
//     };
//     return new Promise((resolve, reject) => {
//         axios.get(url, config).then(err => {
//             resolve(err.data);
//             if (!err) {
//                 return
//             }
//             let url = window.URL.createObjectURL(err.data);
//             let link = document.createElement('a');
//             link.style.display = 'none';
//             link.href = url;
//             link.setAttribute('download', `${fileName}.${fileType}`);
//             document.body.appendChild(link);
//             link.click();
//         }).catch(err => {
//             reject(err.data);
//         })
//     });
// }

export default {
  get,
  post,
  // put,
  // del,
  // download,
};
