<template>
  <div class="box2" v-show="isSzjcDisplay">
    <img src="../static/off.png" class="offImg" @click="offEeacharBox" />
    <div class="nowDataBox">
      <div class="nowDataBox__title">
        <div class="nowDataBox__txt">实时数据</div>
        <div class="nowDataBox__cjtime">采集时间：{{ data.cjss }}</div>
      </div>
      <div class="nowDataBox__contents">
        <div class="nowDataBox__sw">
          <span>水温：</span><span>{{ data.sw }}</span
          ><span>℃</span>
        </div>
        <div class="nowDataBox__ry">
          <span>水质溶解氧：</span><span>{{ data.ry }}</span
          ><span>mg/L</span>
        </div>
        <div class="nowDataBox__ph">
          <span>水质PH：</span><span>{{ data.ph }}</span
          ><span>PH</span>
        </div>
        <div class="nowDataBox__ddl">
          <span>电导率：</span><span>{{ data.ddl }}</span
          ><span>US/CM</span>
        </div>
      </div>
      <div class="nowDataBox__eachars"></div>
    </div>
    <div class="weekavgBox">
      <div id="weekavgBoxEchars"></div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, onMounted, reactive, ref, toRefs, watchEffect } from "vue";
// import { receiveMessage } from "@/eventProcessing";
import * as echarts from 'echarts';
// import { useRouter } from 'vue-router'
// import { ElSlider } from "element-plus";
import * as Bigscreen from "../api/bigscreen.js";

export default defineComponent({
  name: "popEchars",
  components: {},
  emits:['offEeacharBox'],
  props: { isSzjcDisplay: Object ,poiDetail:String},
  setup(props, { emit }) {
    emit
    const state = reactive(props)
    const { isSzjcDisplay,poiDetail} = toRefs(state);
    const isDisPlay = ref(false);
    const data = reactive({
      "cjss": "          ",
      "sw": "     ",
      "ry": "    ",
      "ph": "    ",
      "ddl": "      "
    })
    const option = {
      title: {
        text: ''
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        type: "plain",
        data: ['水温', '水质溶解氧', '水质PH', '电导率'],
        itemStyle: {},
        top: 5,
        left: -5,
        itemGap: 10,
        textStyle: {
          color: "rgba(255, 255, 255, 0.6)",
          fontStyle: 'normal',
          fontSize: 9
        },
        selected: { "水温": true, "水质溶解氧": true, "水质PH": true, "电导率": false }
      },
      grid: {
        top: '20%',
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['2023-04-01', '2023-04-02', '2023-04-03', '2023-04-04', '2023-04-05'],
        axisLine: {
          lineStyle: {
            color: "#FFFFFF"
          },
          show: true
        },
        axisLabel: {
          color: "#FFFFFF",
          fontSize: '7'
        },
        splitLine: {
          show: false
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: "#FFFFFF"
          },
          show: true
        },
        axisLabel: {
          color: "#FFFFFF",
          fontSize: '8'
        }
      },
      series: [
        {
          name: '水温',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: '水质溶解氧',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [220, 182, 191, 234, 290, 330, 310]
        },
        {
          name: '水质PH',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [150, 232, 201, 154, 190, 330, 410]
        },
        {
          name: '电导率',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [320, 332, 301, 334, 390, 330, 320]
        }
      ]
    };
    watchEffect(()=>{
      if(isSzjcDisplay.value==true){
        getData()
      }
    })
    const getData = async () => {
      const name=JSON.parse(poiDetail.value).textContent;
      const deviceId=name.indexOf('1')!='-1'?'865328068411643':name.indexOf('2')!='-1'?'865328068419562':name.indexOf('3')!='-1'?'865328068331858':'';
      // const day7 = await Bigscreen.selectWeekAvgData({ "deviceId": deviceId });
      const one = await Bigscreen.selectNewTimeData({ "deviceId": deviceId });
      const day7={
        code : 0,
        data:[{"waterTemperature":19.05,"waterQuality":4.99,"phValue":9.18,"conductivity":3.0,"turbidity":0.0,"creatTime":"2023-04-11"},
              {"waterTemperature":18.77,"waterQuality":4.56,"phValue":6.63,"conductivity":3.0,"turbidity":0.0,"creatTime":"2023-04-09"},
              {"waterTemperature":20.38,"waterQuality":7.1,"phValue":6.62,"conductivity":2.0,"turbidity":0.0,"creatTime":"2023-04-08"},
              {"waterTemperature":21.13,"waterQuality":3.56,"phValue":6.94,"conductivity":1.0,"turbidity":0.0,"creatTime":"2023-04-07"},
              {"waterTemperature":20.37,"waterQuality":7.11,"phValue":7.95,"conductivity":1.0,"turbidity":0.0,"creatTime":"2023-04-06"},
              {"waterTemperature":22.42,"waterQuality":7.1,"phValue":6.62,"conductivity":2.0,"turbidity":0.0,"creatTime":"2023-04-05"},
              {"waterTemperature":20.43,"waterQuality":7.11,"phValue":7.92,"conductivity":3.0,"turbidity":0.0,"creatTime":"2023-04-04"}]
      }

      if (one.code == 0 && one.data) {
        data.cjss = one.data.creatTime || data.cjss;
        data.sw = one.data.waterTemperature || data.sw;
        data.ry = one.data.waterQuality || data.ry;
        data.ph = one.data.phValue || data.ph;
        data.ddl = one.data.conductivity | data.ddl;
      }
      if (day7.code == 0 && day7.data) {
        data.sw7 = [];
        data.ry7 = []
        data.ph7 = []
        data.ddl7 = [];
        data.xLable = []
        for (let res of day7.data) {
          data.sw7.push(res.waterTemperature)
          data.ry7.push(res.waterQuality)
          data.ph7.push(res.phValue)
          data.ddl7.push(res.conductivity)
          data.xLable.push(new Date(res.creatTime).format("MM-dd"))
        }
      }
      const myChart = echarts.init(document.getElementById('weekavgBoxEchars'));
      option.series[0].data = data.sw7;
      option.series[1].data = data.ry7;
      option.series[2].data = data.ph7;
      option.series[3].data = data.ddl7;
      option.xAxis.data = data.xLable
      myChart.setOption(option);
      // console.log(day7)
    }
    onMounted(() => {
      // 基于准备好的dom，初始化echarts实例
      //使用刚指定的配置项和数据显示图表。
    })
    // receiveMessage('clickhhhhPoi', async (res) => {
    //         isDisPlay.value = true
    //         getData(res.id)
    // })
    const offEeacharBox = () => {
      emit('offEeacharBox',{msg:'offEeacharBox'})
      // isSzjcDisplay.value = false
    }
    // eslint-disable-next-line vue/no-dupe-keys
    return { isDisPlay, getData, data, isSzjcDisplay,offEeacharBox };
  },
});
</script>

<style scope>
.box2 {
  /* overflow: hidden; */
  /* transform: scale(3.4285714285714284, 4.153846153846154);
  transform-origin: 0px 0px; */
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 400px;
  background: rgba(5, 18, 33, 0.9);
  opacity: 0.8;
  border: 1px solid #1e506f;
}

.nowDataBox {
  width: 100%;
  height: 218px;
  margin-top: 15px;
}

.nowDataBox__title {
  background: linear-gradient(90deg, #3e6aa1 0%, rgba(62, 106, 161, 0) 100%);
  opacity: 0.5;
  width: 273px;
  height: 36px;
}

.nowDataBox__txt {
  float: left;
  margin-left: 25px;
  font-family: "江城斜黑体";
  font-size: 22px;
  line-height: 36px;
  align-items: center;
  background: linear-gradient(180deg, #ffffff 36.11%, #98d2f3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 2px 8px rgba(152, 210, 243, 0.5);
}

.nowDataBox__cjtime {
  float: left;
  margin-left: 10px;
  position: relative;
  top: 25px;
  font-family: "Alibaba PuHuiTi 2.0";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #ffffff;
  /* transform: scale(0.8); */
  zoom: 0.8;
}

.nowDataBox__contents {
  padding: 22px 0 28px 25px;
}

.nowDataBox__contents div:not(:first-child) {
  overflow: hidden;
  margin-top: 15px;
}

.nowDataBox__contents div:nth-child(2) {
  overflow: hidden;
  /* margin-top: 15px; */
}

.nowDataBox__contents div span:nth-child(1) {
  font-family: "Alibaba PuHuiTi 2.0";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #ffffff;
  width: 83px;
  display: block;
  float: left;
}

.nowDataBox__contents div span:nth-child(2) {
  font-family: "Alibaba PuHuiTi 2.0";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #98d2f3;
  /* width: 76px; */
  display: block;
  float: left;
  margin-left: 30px;
}

.nowDataBox__contents div span:nth-child(3) {
  font-family: "Alibaba PuHuiTi 2.0";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #98d2f3;
}

.nowDataBox__contents .nowDataBox__sw span:nth-child(3) {
  zoom: 0.7;
  position: relative;
  top: 5px;
  left: 3px;
}

.nowDataBox__contents .nowDataBox__ry span:nth-child(3) {
  zoom: 0.65;
  position: relative;
  top: 5px;
  left: 4px;
}

.nowDataBox__contents .nowDataBox__ph span:nth-child(3) {
  zoom: 0.7;
  position: relative;
  top: 7px;
  left: 4px;
}

.nowDataBox__contents .nowDataBox__ddl span:nth-child(3) {
  zoom: 0.65;
  position: relative;
  top: 5px;
  left: 5px;
}

.weekavgBox {
  border-top: 1px solid #1e506f;
  width: 280px;
  height: 182px;
  margin: 0 10px;
  /* height: 100px; */
  /* background: aqua; */
}

#weekavgBoxEchars {
  width: 100%;
  height: 100%;
}

.offImg2 {
  position: absolute;
  display: block;
  right: 10px;
  top: 5px;
  width: 20px;
  height: 20px;
}

.offImg2:hover {
  background: rgba(15, 170, 187, 0.7);
  border-radius: 50%;
}
</style>
