<template>
  <div :class="isLeft ? 'checkboxs1' : 'checkboxs2'">
    <div
      class="checkbox"
      v-for="(item, index) in groupList"
      :key="index"
      v-show="ssTo && ssTo.includes(item)"
    >
      <input
        type="checkbox"
        :name="item"
        :id="item + 'box1'"
        @change="checkbox2"
        v-model="displayGroup[item]"
        style="display: none"
        class="checkinput"
      />
      <!-- style="visibility: hidden" -->
      <label :for="item + 'box1'" class="vircheckinput"></label>
      <span class="checktext">{{ item }}</span>
    </div>
  </div>
</template>

<script lang="js">
import {  defineComponent, ref , onMounted,reactive  } from "vue";
import { useRouter } from 'vue-router'
import { isMobile } from 'react-device-detect';
import {  receiveMessage  } from "@/eventProcessing";

// import { ElSlider } from "element-plus";

export default defineComponent({
  name: "checkbox",
  components:{  },
  emits:['changeBox'],
  setup(props,{emit}) {
    const value = ref(0);
    const slider = ref(null);
    const typeToTxt = {
                    "乡村概况":["宅基地","塘口","林地","基本农田"],
                    "乡村治理":["随手拍","老年人","网格员","网格长","残疾人","优抚对象","网格"],
                    "基层党建":["党员","党组织","网格"],
                    "乡村生态":["排涝站","闸门","配电房","水利设施","养路站","垃圾站","污水处理","公厕","河道","监控"],
                    "基层服务":["公共场所","卫生站","村务机构","兴楼粮站","理发店","商店","浴室"],
                    "第一产业":["虾塘棚","塘口","高位池","水质检测","饲料仓库","虾苗企业","生态专用肥生产企业","虾药品肥生产企业","饲料企业"],
                    "第二产业":["涉农企业","成虾销售经纪人","虾苗经纪人","水制厂","碾米厂"],
                    "第三产业":["电商服务点","商店","渔药门市部"],
              }
    const people=["宅基地","党员","老年人","网格员","残疾人","网格长","优抚对象"];
    //  people
    const displayGroupJson={}
    for(let key in  typeToTxt){
      typeToTxt[key].forEach(element => {displayGroupJson[element]=false});
    }
    const displayGroup=reactive(displayGroupJson)
    const groupList=ref(Object.keys(displayGroup))
    let localDisplayGroup=null;
    if(!window.localStorage.getItem("displayGroup")){
         window.localStorage.setItem("displayGroup",JSON.stringify(displayGroup))
    }
    localDisplayGroup=JSON.parse(window.localStorage.getItem("displayGroup"))
    const router = useRouter();
    const ssTo=ref(null);
    const isLeft=ref(isMobile);

      // "垃圾站",
      // "人员",
      // "承包土地",
      // "垃圾分类",
      // "厕所",
      // "基本农田",
      // "耕地地块",
      // "虾苗企业",
      // "集体土地地块",
      // "虾销售经纪人",
      // "园地",
      // "塘口",
      // "水产企业",
      // "污水处理",
      // "虾苗经纪人"

     const checkbox2=(e)=>{
        // console.log(e.srcElement.checked)
        // console.log(displayGroup[tag])
        // console.log(tag)
        if(people.includes(e.srcElement.name)){
          // console.log()
          for(let key of people){
            displayGroup[key]=false
          }
        }
        if(e.srcElement.checked){
            displayGroup[e.srcElement.name]=true
        }else{
            displayGroup[e.srcElement.name]=false
        }
        window.localStorage.setItem("displayGroup",JSON.stringify(displayGroup))
        emit('changeBox',displayGroup)
     }

     onMounted(()=>{
      const text=router.currentRoute.value.query.text
          if(text){
              ssTo.value=typeToTxt[text]
              // "基层服务":["集体土地地块","基本农田"],
              // "第一产业":["虾苗企业","水产企业","塘口","卖总肥药"],//生产
              // "第二产业":["虾销售经纪人","虾苗经纪人","加工企业"],//加工，虾罐头
              // "第三产业":["民宿","饭店","旅游景点"],//服务旅游
          }else{
              ssTo.value=Object.keys(localDisplayGroup)
          }
          for(let key in localDisplayGroup){
            if(!ssTo.value.includes(key)){
              displayGroup[key]=false
            }else
              displayGroup[key]=localDisplayGroup[key]
          }
          window.localStorage.setItem("displayGroup",JSON.stringify(displayGroup))
          emit('changeBox',displayGroup)
          receiveMessage('clickLinkAdress',(res)=>{
                ssTo.value=typeToTxt[res.text]
                for(let key in localDisplayGroup){
                  if(!ssTo.value.includes(key)){
                    displayGroup[key]=false
                  }else
                    displayGroup[key]=localDisplayGroup[key]
                }
                window.localStorage.setItem("displayGroup",JSON.stringify(displayGroup))
                emit('changeBox',displayGroup)
          })
     })
    //  const style={ }
      return { value , slider ,checkbox2,displayGroup,groupList,ssTo,isLeft};
    },
});
</script>

<style scope>
.slider-demo-block {
  position: fixed;
  left: 0px;
  top: 300px;
}

.checkboxs2 {
  position: absolute;
  /* bottom: 5%; */
  /* display: none; */
  color: aliceblue;
  bottom: 13%;
  left: 50%;
  /* bottom: 2%; */
  transform: translateX(-50%);
}
.checkboxs2 .checkbox {
  overflow: hidden;
  height: 30px;
  float: left;
  margin-left: 10px;
  /* position: relative; */
}

.checkboxs2 .checkbox .checkinput + .vircheckinput {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid skyblue;
  border-radius: 2px;
  /* transform: translateY(-50%); */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.checkboxs2 .checkbox .checkinput:checked + .vircheckinput {
  background: url(../static/check.png) no-repeat;
  background-size: 100% 100%;
}

.checkboxs1 {
  position: absolute;
  /* bottom: 5%; */
  /* display: none; */
  color: aliceblue;
  left: 1.7%;
  top: 20%;
  /* bottom: 2%; */
  /* transform: translateY(-50%); */
}
.checkboxs1 .checkbox {
  font-size: 30px;
  line-height: 80px;
}
.checkboxs1 .checkbox .checkinput + .vircheckinput {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid skyblue;
  border-radius: 2px;
  /* transform: translateY(-50%); */
  /* position: relative;
  top: 50%;
  transform: translateY(-50%); */
}
.checkboxs1 .checkbox .checktext {
  margin-left: 8px;
  position: relative;
  top: -15px;
}
.checkboxs1 .checkbox .checkinput:checked + .vircheckinput {
  background: url(../static/check.png) no-repeat;
  background-size: 100% 100%;
}
</style>
